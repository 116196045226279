import { defineStore, acceptHMRUpdate } from "pinia";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    access_token: "",
    user: {
      name: "",
    },
  }),

  actions: {
    setAccessToken(accessToken: string) {
      this.access_token = accessToken;
    },
    setUser: (user: any) => {
      console.log(user);
    },
  },

  persist: {
    storage: persistedState.cookiesWithOptions({
      secure: true,
      sameSite: "strict",
    }),
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
